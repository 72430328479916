import { MdChevronRight } from "react-icons/md"

const ColumnTitleInput: React.FC<{
    value: string,
    onChange: (value: string) => void,
    onSubmit: () => void
}> = ({ value, onChange, onSubmit }) => {

    const handleEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.charCode === 13 || event.keyCode === 13) onSubmit()
    }

    return (<div className="d-flex align-items-center column-title-input">
        <input
            value={value}
            onChange={(event) => onChange(event.target.value)}

            onBlur={onSubmit}
            onKeyDown={handleEnter}

            autoFocus
        />
        <div className="icon-btn">
            <MdChevronRight onClick={onSubmit} />
        </div>
    </div>)
}

export default ColumnTitleInput