import { Droppable, Draggable } from 'react-beautiful-dnd';

import TaskModel from "../models/Task";
import ColumnModel from "../models/Column";
import NewTaskButton from '../components/NewTaskButton';
import Task from "./Task";
import ColumnTitle from '../components/ColumnTitle';

const Column: React.FC<{
    column: ColumnModel,
    index: number,
    onNewTask: (task: TaskModel) => void,
    onTaskChange: (oldTask: TaskModel, newTask: TaskModel) => void,
    onColumnChange: (column: ColumnModel) => void,
    onTaskDelete: (task: TaskModel) => void,
    onDelete: () => void,
}> = ({ column, index, onNewTask, onColumnChange, onTaskChange, onTaskDelete, onDelete }) => {
    const columnKey = `${index}`
    return (
        <Draggable draggableId={columnKey} index={index}>
            {(draggable) =>
                <Droppable droppableId={columnKey} type="TASK">
                    {(droppable) =>
                        <div className="column"  {...draggable.draggableProps} ref={draggable.innerRef}>
                            <div {...draggable.dragHandleProps}>
                                <ColumnTitle
                                    value={column.getName()}
                                    onTitleChanged={(value) => onColumnChange(column.setName(value))}
                                    onDelete={onDelete}
                                />
                            </div>
                            <div
                                className="column-content"
                                {...droppable.droppableProps}
                                ref={droppable.innerRef}
                            >
                                {column.getTasks().map((task, key) =>
                                    <Task
                                        task={task}
                                        columnKey={columnKey}
                                        key={key}
                                        index={key}
                                        onTaskDelete={() => onTaskDelete(task)}
                                        onTaskChange={(newTask) => onTaskChange(task, newTask)} />
                                )}
                                {droppable.placeholder}
                            </div>
                            <NewTaskButton onNewTask={onNewTask} />
                        </div>
                    }
                </Droppable>
            }
        </Draggable>
    )
}

export default Column;