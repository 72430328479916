import { List } from "immutable";
import Column, { ColumnJSONInterface } from "./Column";
import { Columns } from "./Columns";
import Task from "./Task";

interface WorkspaceInterface {
    name: string,
    columns: Columns,
}

type WorkspaceJSONInterface = Omit<WorkspaceInterface, "columns"> & {
    columns: ColumnJSONInterface[]
}

export default class Workspace {
    readonly name;
    readonly columns: Columns;

    constructor(workspace?: WorkspaceInterface) {
        this.name = workspace?.name || "";
        this.columns = workspace?.columns || List<Column>();
    }

    static fromJSON(data: WorkspaceJSONInterface): Workspace {
        return createWorkspace({
            ...data, columns: List(data.columns.map((columnJson) => Column.fromJSON(columnJson)))
        })
    }

    toJson(): WorkspaceJSONInterface {
        return { name: this.name, columns: this.columns.map(column => column.toJson()).toArray() }
    }

    private setColumns(columns: Columns): Workspace {
        return createWorkspace(this, { columns })
    }

    setName(name: string) {
        return createWorkspace(this, { name })
    }

    getName(): string {
        return this.name;
    }

    getColumns(): Columns {
        return this.columns
    }

    findColumnByTask(task: Task) {
        return this.columns.find((column) => column.getTasks().contains(task))
    }

    addTask(column: Column, task?: Task): Workspace {
        const columnIndex = this.getColumnIndex(column)

        const newTasks = column.getTasks().push(task || new Task());
        const newColumns = this.columns.set(columnIndex, column.setTasks(newTasks));
        return this.setColumns(newColumns)
    }

    changeTask(oldTask: Task, newTask: Task): Workspace {
        const oldColumn = this.findColumnByTask(oldTask)
        if (!oldColumn) throw new Error("Invalid task provided")
        const columnIndex = this.getColumnIndex(oldColumn)
        const taskIndex = oldColumn.getTasks().indexOf(oldTask);

        const newColumn = oldColumn.setTasks(oldColumn.getTasks().set(taskIndex, newTask))
        const newColumns = this.columns.set(columnIndex, newColumn)

        return this.setColumns(newColumns);
    }

    moveTask(task: Task, newTaskIndex: number, newColumn?: Column): Workspace {
        const oldColumn = this.findColumnByTask(task)
        if (!oldColumn) throw new Error("Invalid task provided")
        const oldTaskIndex = oldColumn.getTasks().indexOf(task);
        const oldColumnIndex = this.getColumnIndex(oldColumn)

        newColumn = newColumn || oldColumn
        const newColumnIndex = this.getColumnIndex(newColumn)


        let newColumns = this.columns;

        if (oldColumn === newColumn) {
            newColumn = oldColumn.setTasks(oldColumn.getTasks().delete(oldTaskIndex))
            newColumn = newColumn.setTasks(newColumn.getTasks().insert(newTaskIndex, task))
            newColumns = newColumns.set(oldColumnIndex, newColumn)
        } else {
            newColumns = newColumns.set(oldColumnIndex, oldColumn.setTasks(oldColumn.getTasks().delete(oldTaskIndex)))
            newColumns = newColumns.set(newColumnIndex, newColumn.setTasks(newColumn.getTasks().insert(newTaskIndex, task)))
        }

        return this.setColumns(newColumns);
    }

    deleteTask(task: Task) {
        const column = this.findColumnByTask(task)
        if (!column) throw new Error("Invalid task provided")
        const taskIndex = column.getTasks().indexOf(task);
        const columnIndex = this.getColumnIndex(column)

        const newColumn = column.setTasks(column.getTasks().remove(taskIndex))
        const newColumns = this.columns.set(columnIndex, newColumn)

        return this.setColumns(newColumns)
    }

    addColumn(column?: Column): Workspace {
        const newColumns = this.columns.push(column || new Column());
        return this.setColumns(newColumns)
    }

    moveColumn(column: Column, newColumnIndex: number): Workspace {
        const oldColumnIndex = this.getColumnIndex(column)
        const newColumns = this.columns.remove(oldColumnIndex).insert(newColumnIndex, column);
        return this.setColumns(newColumns);
    }

    changeColumn(oldColumn: Column, newColumn: Column): Workspace {
        const oldColumnIndex = this.getColumnIndex(oldColumn)
        const newColumns = this.columns.set(oldColumnIndex, newColumn);
        return this.setColumns(newColumns);
    }

    deleteColumn(column: Column) {
        const columnIndex = this.getColumnIndex(column)
        const newColumns = this.columns.remove(columnIndex);
        return this.setColumns(newColumns);
    }

    getColumnIndex(column: Column): number {
        const index = this.getColumns().indexOf(column)
        if (index < 0) throw new Error("Invalid column provided")
        return index;
    }
}

const createWorkspace = (workspace: WorkspaceInterface, values?: Partial<WorkspaceInterface>) =>
    new Workspace(values ? { ...workspace, ...values } : workspace)