import { createRef, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const AddWorkspaceDialog: React.FC<{
    onAdd: (name: string) => void
    onCancel: () => void,
    isOpen: boolean
}> = ({ onAdd, onCancel, isOpen }) => {
    const inputRef = createRef<HTMLInputElement>();

    const [name, setName] = useState("");

    const add = () => {
        onAdd(name)
        setName("")
    }

    const handleEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.charCode === 13 || event.keyCode === 13) add()
    }

    return (<>
        <Modal centered show={isOpen} onHide={onCancel} onEntered={() => inputRef.current?.focus()}>
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1">
                    Set workspace name
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="workspace-name">Workspace name:</label>
                    <input
                        ref={inputRef}
                        className="form-control"
                        name={"workspace-name"}
                        onKeyDown={handleEnter}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="primary" onClick={add}>Save</Button>
            </Modal.Footer>
        </Modal>
    </>)
}


export default AddWorkspaceDialog;
