import { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import ColumnModel from '../models/Column';
import ColumnTitleInput from './TitleInput';

const NewColumnButton: React.FC<{ onNewColumn: (column: ColumnModel) => void }> = ({ onNewColumn }) => {
    const [isInput, setIsInput] = useState(false);
    const [title, setTitle] = useState("");

    const onInputSubmitted = () => {
        if (!title) return setIsInput(false);

        onNewColumn(new ColumnModel().setName(title))
        setTitle("")
        setIsInput(false)
    }


    return (
        <div className="column">
            {isInput ?
                <ColumnTitleInput onChange={setTitle} onSubmit={onInputSubmitted} value={title} />
                :
                <div className="column-button" onClick={() => setIsInput(true)}>
                    <MdAdd /><span className="ml-1">Add new column</span>
                </div>
            }
        </div>
    )
}

export default NewColumnButton;