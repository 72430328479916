import { useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import ColumnTitleInput from './TitleInput';
import ConfirmedDelete from './ConfirmedDelete';

const ColumnTitle: React.FC<{
    value: string,
    onTitleChanged: (value: string) => void,
    onDelete: () => void,
}> = ({ value, onTitleChanged, onDelete }) => {
    const [isInput, setIsInput] = useState(false);
    const [input, setInput] = useState("");

    const onInputSubmitted = () => {
        if (!input) return setIsInput(false);

        onTitleChanged(input)
        setIsInput(false)
    }

    const editTitle = () => {
        setIsInput(true)
        setInput(value)
    }

    if (isInput) return <ColumnTitleInput onChange={setInput} onSubmit={onInputSubmitted} value={input} />

    return (
        <div className={`d-flex align-items-center column-title`}>
            <h2 className="flex-grow-1 mb-0" onClick={editTitle}>
                {value}
            </h2>
            <div className="icon-btn">
                <MdEdit onClick={editTitle} />
            </div>
            <div className="icon-btn">
                <ConfirmedDelete onDelete={onDelete}>
                    {(open) => <MdDelete onClick={open} />}
                </ConfirmedDelete>
            </div>
        </div>
    )
}

export default ColumnTitle;