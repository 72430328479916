import AddWorkspaceDialog from '../components/AddWorkspaceDialog';
import Alert from 'react-bootstrap/Alert'
import { MdAdd } from "react-icons/md";
import { useEffect, useState } from 'react';

import WorkspaceModel from '../models/Workspace';
import Workspace from './Workspace';

const WorkspaceManager: React.FC<{}> = () => {
    const loadWorkspaces = () => {
        let rawWorkspaces = localStorage.getItem('app-workspaces')
        if (rawWorkspaces) return JSON.parse(rawWorkspaces);
        return [];
    }

    const saveWorkspaces = () => {
        localStorage.setItem('app-workspaces', JSON.stringify(workspaces))
    }

    const [workspaces, setWorkspaces] = useState<string[]>(loadWorkspaces())
    const [isAdding, setIsAdding] = useState(false);
    const [activeWorkspce, setActiveWorkspace] = useState<string | null>(null);
    const [workspace, setWorkspace] = useState<WorkspaceModel | null>(null);
    const [showAlert, setShowAlert] = useState(false);

    const addWorkspace = (name: string) => {
        const trimmedName = name.trim()

        if (trimmedName === "app-workspaces" || trimmedName === "") {
            return;
        }
        if (workspaces.includes(trimmedName)) {
            setShowAlert(true)
            return;
        }

        setWorkspaces(loadWorkspaces())
        workspaces.push(trimmedName)
        setWorkspaces(workspaces)
        localStorage.setItem(trimmedName, JSON.stringify((new WorkspaceModel().setName(trimmedName).toJson())))
        saveWorkspaces()
    }

    // Load and open
    useEffect(() => {
        if (!activeWorkspce) {
            setWorkspace(null);
            return;
        }

        const rawWorkspace = localStorage.getItem(activeWorkspce);
        if (!rawWorkspace) return
        setWorkspace(WorkspaceModel.fromJSON(JSON.parse(rawWorkspace)));
    }, [activeWorkspce])

    // Saving
    useEffect(() => {
        if (!workspace) return
        localStorage.setItem(workspace.getName(), JSON.stringify(workspace.toJson()));
    }, [workspace])


    return (<>
        {(workspace !== null) ?
            <Workspace
                initialWorkspace={workspace}
                onWorkspaceChange={setWorkspace}
                onClose={() => setActiveWorkspace(null)}
            />
            :
            <div className="workspace-manager">
                {showAlert &&
                    <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className="w-100">
                        <Alert.Heading>Workspace already exists</Alert.Heading>
                        <p>
                            Workspace with this name already exists. Choose a different name.
                    </p>
                    </Alert>
                }
                <div className="workspaces">
                    {workspaces.map((name, key) =>
                        <div className="workspace-button" onClick={() => setActiveWorkspace(name)} key={key}>
                            <h1>{name}</h1>
                        </div>
                    )}
                    <div className="workspace-button" onClick={() => setIsAdding(true)}>
                        <h1><MdAdd /></h1>
                    </div>
                </div>
                <AddWorkspaceDialog
                    onCancel={() => setIsAdding(false)}
                    onAdd={(name) => { setIsAdding(false); addWorkspace(name) }}
                    isOpen={isAdding} />
            </div>
        }
    </>)

}

export default WorkspaceManager;