import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd'
import TaskEdit from './TaskEdit';

import TaskModel from "../models/Task";
import { MdTimer } from 'react-icons/md';

const Task: React.FC<{
    task: TaskModel,
    index: number,
    columnKey: string,
    onTaskChange: (task: TaskModel) => void,
    onTaskDelete: () => void
}> = ({ task, index, columnKey, onTaskChange, onTaskDelete }) => {
    const [isEdited, setIsEdited] = useState(false);

    const taskKey = `${columnKey}-task-${index}`;
    return (<>
        <Draggable draggableId={taskKey} index={index}>
            {(provided) =>
                <div
                    className="task"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={() => setIsEdited(true)}
                >
                    <b>{task.getName()}</b>
                    <p>{task.getDescription()}</p>
                    {task.getDue() &&
                        <div className="small mt-1">
                            <MdTimer /> {task.getDue()?.format("MMM D")}
                        </div>
                    }
                </div>
            }
        </Draggable>
        <TaskEdit
            task={task}
            isOpen={isEdited}
            onClose={() => setIsEdited(false)}
            onDelete={onTaskDelete}
            onTaskChange={onTaskChange} />
    </>)
}

export default Task;