import React from "react"
import Workspace from "../models/Workspace"
import Column from "../models/Column";
import Task from "../models/Task";

import WorkspaceContext, { WorkspaceContextType } from "../controllers/WorkspaceContext";

interface WithWorkspaceProps {
    initialWorkspace: Workspace,
    onWorkspaceChange?: (workspace: Workspace) => void
}

export interface InjectedWorkspaceProps {
    changeColumn: (oldValue: Column, newValue: Column) => void
    addColumn: (column?: Column) => void
    addTask: (column: Column, task?: Task) => void
    deleteColumn: (column: Column) => void
    moveColumn: (column: Column, newColumnIndex: number) => void
    changeTask: (oldTask: Task, newTask: Task) => void
    deleteTask: (task: Task) => void,
    moveTask: (task: Task, newTaskIndex: number, newColumn?: Column) => void
}

const withWorkspace = <P extends InjectedWorkspaceProps>(Component: React.ComponentType<P>) =>
    class WithWorkspace extends React.Component<
        Omit<P, keyof InjectedWorkspaceProps> & WithWorkspaceProps,
        WorkspaceContextType
        > {

        constructor(props: Omit<P, keyof InjectedWorkspaceProps> & WithWorkspaceProps) {
            super(props)
            this.state = {
                workspace: props.initialWorkspace,
                setWorkspace: (workspace) => this.setState({ workspace })
            }
        }

        componentDidUpdate(_: any, prevState: WorkspaceContextType) {
            if (this.state.workspace !== prevState.workspace) {
                this.props.onWorkspaceChange && this.props.onWorkspaceChange(this.state.workspace)
            }
        }

        changeColumn(oldValue: Column, newValue: Column) {
            this.setState({
                workspace: this.state.workspace.changeColumn(oldValue, newValue)
            })
        }

        addColumn(column?: Column) {
            this.setState({
                workspace: this.state.workspace.addColumn(column)
            })
        }

        moveColumn(column: Column, newColumnIndex: number) {
            this.setState({
                workspace: this.state.workspace.moveColumn(column, newColumnIndex)
            })
        }

        addTask(column: Column, task?: Task) {
            this.setState({
                workspace: this.state.workspace.addTask(column, task)
            })
        }

        deleteColumn(column: Column) {
            this.setState({
                workspace: this.state.workspace.deleteColumn(column)
            })
        }

        changeTask(oldTask: Task, newTask: Task) {
            this.setState({
                workspace: this.state.workspace.changeTask(oldTask, newTask)
            })
        }

        deleteTask(task: Task) {
            this.setState({
                workspace: this.state.workspace.deleteTask(task)
            })
        }

        moveTask(task: Task, newTaskIndex: number, newColumn?: Column) {
            this.setState({
                workspace: this.state.workspace.moveTask(task, newTaskIndex, newColumn)
            })
        }

        render() {
            const { initialWorkspace, ...props } = this.props
            const componentProps = props as unknown as P

            const injectedProps: InjectedWorkspaceProps = {
                changeColumn: this.changeColumn.bind(this),
                addColumn: this.addColumn.bind(this),
                addTask: this.addTask.bind(this),
                deleteColumn: this.deleteColumn.bind(this),
                changeTask: this.changeTask.bind(this),
                deleteTask: this.deleteTask.bind(this),
                moveColumn: this.moveColumn.bind(this),
                moveTask: this.moveTask.bind(this),
            }

            return <WorkspaceContext.Provider value={this.state}>
                <Component
                    {...componentProps}
                    {...injectedProps}
                />
            </WorkspaceContext.Provider>
        }
    }


export default withWorkspace