import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import TitleInput from './TitleInput';

const TaskEditableTitle: React.FC<{
    value: string,
    onTitleChanged: (value: string) => void
}> = ({ value, onTitleChanged }) => {
    const [isInput, setIsInput] = useState(false);
    const [input, setInput] = useState("");

    const onInputSubmitted = () => {
        if (!input) return setIsInput(false);

        onTitleChanged(input)
        setIsInput(false)
    }

    const editTitle = () => {
        setIsInput(true)
        setInput(value)
    }

    if (isInput) return <TitleInput onChange={setInput} onSubmit={onInputSubmitted} value={input} />


    return (
        <div className={`d-flex align-items-center`}>
            <h2 className="mb-0" onClick={editTitle}>
                {value}
            </h2>
            <div className="icon-btn ml-2">
                <MdEdit onClick={editTitle} />
            </div>
        </div>
    )
}

export default TaskEditableTitle;