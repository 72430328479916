import { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import TaskModel from '../models/Task';

const NewTaskButton: React.FC<{ onNewTask: (task: TaskModel) => void }> = ({ onNewTask }) => {
    const [isInput, setIsInput] = useState(false);
    const [name, setName] = useState("");

    const handleEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.charCode === 13 || event.keyCode === 13) onInputSubmitted(event)
    }

    const onInputSubmitted = (event: React.SyntheticEvent) => {
        if (!name) return setIsInput(false);

        onNewTask(new TaskModel().setName(name))
        setName("")
        setIsInput(false)
    }

    if (isInput) {
        return (
            <textarea
                className="task"
                rows={2}
                placeholder={"Input task name"}

                value={name}
                onChange={(event) => setName(event.target.value)}

                onBlur={onInputSubmitted}
                onKeyDown={handleEnter}

                autoFocus
            ></textarea>
        )
    }

    return (
        <div className="column-button" onClick={() => setIsInput(true)}>
            <MdAdd /><span className="ml-1">Add new task</span>
        </div>
    )
}

export default NewTaskButton;