import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmedDelete: React.FC<{
    onDelete: () => void
    onCancel?: () => void,
    children: (open: () => void) => React.ReactNode
}> = ({ onDelete, onCancel, children }) => {

    const [isOpen, setIsOpen] = useState(false);

    const onHide = () => {
        setIsOpen(false)
        onCancel && onCancel()
    }

    const onConfirm = () => {
        setIsOpen(false)
        onDelete()
    }

    return (<>
        <Modal show={isOpen} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you really want to delete this column?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="danger" onClick={onConfirm}>Delete</Button>
            </Modal.Footer>
        </Modal>
        { children(() => setIsOpen(true))}
    </>)
}

export default ConfirmedDelete;
