import React from "react";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import WorkspaceModel from '../models/Workspace'

import Column from "./Column";
import NewColumnButton from "../components/NewColumnButton";
import WorkspaceContext from "../controllers/WorkspaceContext";
import withWorkspace, { InjectedWorkspaceProps } from "../controllers/withWorkspace";
import { MdChevronLeft } from "react-icons/md";

class Workspace extends React.Component<InjectedWorkspaceProps & { onClose: () => void }> {
    static contextType = WorkspaceContext

    onDragEnd({ source, destination, type }: DropResult) {
        const { workspace } = this.context

        // Dropped outside of Droppable
        if (!destination) return;

        if (type === "COLUMN") {
            const column = workspace.getColumns().get(source.index)
            if (!column) throw new Error("Invalid column '" + source.index + "'");

            return this.props.moveColumn(column, destination.index)
        }

        const srcColumn = workspace.getColumns().get(parseInt(source.droppableId, 10))
        if (!srcColumn) throw new Error("Invalid source column '" + source.droppableId + "'");

        const task = srcColumn.getTasks().get(source.index)
        if (!task) throw new Error("Invalid task on index '" + source.index + "'");

        const destColumn = source.droppableId === destination.droppableId ?
            srcColumn :
            workspace.getColumns().get(parseInt(destination.droppableId, 10))
        if (!destColumn) throw new Error("Invalid destination column '" + source.index + "'");

        return this.props.moveTask(task, destination.index, destColumn)
    }

    render() {
        const { workspace } = this.context as { workspace: WorkspaceModel };
        if (!workspace) return null;

        return (<div className="workspace-wrapper">
            <div className="workspace-navbar">
                <div className="icon-btn mr-2" onClick={this.props.onClose}><MdChevronLeft /></div>
                <h1>{workspace.getName()}</h1>
            </div>
            <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
                <Droppable droppableId={"workspace"} type="COLUMN" direction={"horizontal"}>
                    {(droppable) =>
                        <div className="workspace"
                            {...droppable.droppableProps}
                            ref={droppable.innerRef}
                        >
                            {workspace.getColumns().map((column, key) =>
                                <Column
                                    column={column}
                                    key={key}
                                    index={key}
                                    onNewTask={(task) => this.props.addTask(column, task)}
                                    onColumnChange={(newColumn) => this.props.changeColumn(column, newColumn)}
                                    onTaskChange={(oldTask, newTask) => this.props.changeTask(oldTask, newTask)}
                                    onTaskDelete={(task) => this.props.deleteTask(task)}
                                    onDelete={() => this.props.deleteColumn(column)}
                                />
                            )}
                            {droppable.placeholder}
                            <NewColumnButton onNewColumn={(column) => this.props.addColumn(column)} />
                        </div>
                    }
                </Droppable>
            </DragDropContext>
        </div>)
    }
}

export default withWorkspace(Workspace);