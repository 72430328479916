import moment from 'moment';
import { Moment } from 'moment';
import DateTime from 'react-datetime';
import { MdClose } from 'react-icons/md';

const DatePicker: React.FC<{
    onChange: (datetime: Moment | null) => void,
    value: Moment | null
}> = ({ onChange, value }) => {

    const renderInput = (props: any) => {
        const clear = () => {
            props.onChange({ target: { value: "" } });
        }

        return (
            <div className="d-flex align-items-center">
                <input className="flex-grow-1" {...props} />
                {value !== null &&
                    <div className="icon-btn ml-2">
                        <MdClose onClick={clear} />
                    </div>
                }
            </div>
        );
    }

    return (
        <DateTime
            renderInput={renderInput}
            onChange={(value) => {
                if (moment.isMoment(value)) return onChange(value)
                return onChange(null)
            }}
            value={value || ""}
        />
    )
}

export default DatePicker;