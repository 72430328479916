import moment from "moment";
import { Moment } from "moment";

interface TaskInterface {
    name: string,
    description: string,
    due: Moment | null
}

export type TaskJSONInterface = Omit<TaskInterface, "due"> & {
    due: string | null
};

export default class Task implements TaskInterface {
    readonly name;
    readonly description;
    readonly due;

    constructor(task?: TaskInterface) {
        this.name = task?.name || "";
        this.description = task?.description || "";
        this.due = task?.due || null;
    }

    static fromJSON(data: TaskJSONInterface): Task {
        let due = null;
        if (data.due !== null) {
            due = moment(data.due)
            if (!due.isValid())
                throw new Error("Invalid moment provided")
        }

        return createTask({ ...data, due })
    }

    toJson(): TaskJSONInterface {
        let due = this.due === null ? null : this.due.format()
        return { name: this.name, description: this.description, due }
    }

    getName(): string {
        return this.name;
    }

    getDescription(): string {
        return this.description;
    }

    setName(name: string): Task {
        return createTask(this, { name })
    }

    setDescripton(description: string): Task {
        return createTask(this, { description })
    }

    getDue(): Moment | null {
        return this.due;
    }

    setDue(due?: Moment | null) {
        if (!due)
            return createTask(this, { due: null })

        if (!moment.isMoment(due) || !due.isValid())
            throw new Error("Invalid moment provided for setDue function")

        return createTask(this, { due })
    }
}

const createTask = (task: TaskInterface, values?: Partial<TaskInterface>) => new Task(values ? { ...task, ...values } : task)