import React from 'react';
import Workspace from '../models/Workspace';

export type WorkspaceContextType = { workspace: Workspace, setWorkspace: (workspace: Workspace) => void }

const WorkspaceContext = React.createContext<WorkspaceContextType>({
    workspace: new Workspace(),
    setWorkspace: () => { }
});
export default WorkspaceContext
