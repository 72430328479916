import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { FaTasks } from 'react-icons/fa';

import TaskEditableTitle from '../components/TaskEditableTitle';
import ConfirmedDelete from '../components/ConfirmedDelete';

import TaskModel from '../models/Task';
import { useState } from 'react';
import DatePicker from '../components/DatePicker';


const TaskEdit: React.FC<{
    task: TaskModel,
    onTaskChange: (task: TaskModel) => void,
    onDelete: () => void,
    onClose: () => void,
    isOpen: boolean,
}> = ({ task, onTaskChange, onClose, onDelete, isOpen }) => {

    const [description, setDescription] = useState(task.getDescription())

    const save = () => onTaskChange(
        task.setDescripton(description)
    )


    return (<>
        <Modal centered show={isOpen} onHide={() => { save(); onClose() }} onEnter={() => setDescription(task.getDescription())} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1">
                    <TaskEditableTitle value={task.getName()} onTitleChanged={(title) => onTaskChange(task.setName(title))} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="task-description">Task description:</label>
                    <textarea
                        className="form-control"
                        rows={3}
                        name={"task-description"}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        onBlur={save}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="task-due">Due date:</label>
                    <DatePicker value={task.getDue()} onChange={(datetime) => onTaskChange(task.setDue(datetime))} />
                </div>
                <div className="form-group row pt-3 justify-content-end">
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <ConfirmedDelete onDelete={onDelete}>
                            {(open) =>
                                <Button variant="danger" block onClick={open}>Delete</Button>
                            }
                        </ConfirmedDelete>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <Button variant="info" block><FaTasks /> Add to Google Tasks</Button>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button variant="secondary" block onClick={onClose}>Close</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

export default TaskEdit;
