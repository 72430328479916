import { List } from 'immutable';
import Task, { TaskJSONInterface } from './Task';
import { Tasks } from './Tasks';

interface ColumnInterface {
    name: string,
    tasks: Tasks,
}

export type ColumnJSONInterface = Omit<ColumnInterface, "tasks"> & {
    tasks: TaskJSONInterface[]
}

export default class Column implements ColumnInterface {
    readonly name;
    readonly tasks;

    constructor(column?: ColumnInterface) {
        this.name = column?.name || "";
        this.tasks = column?.tasks || List<Task>();
    }

    static fromJSON(data: ColumnJSONInterface): Column {
        return createColumn({
            ...data, tasks: List(data.tasks.map((taskJson) => Task.fromJSON(taskJson)))
        })
    }

    toJson(): ColumnJSONInterface {
        return { name: this.name, tasks: this.tasks.map(task => task.toJson()).toArray() }
    }

    setTasks(tasks: Tasks): Column {
        return createColumn(this, { tasks })
    }

    getName(): string {
        return this.name;
    }

    getTasks(): Tasks {
        return this.tasks;
    }

    setName(name: string): Column {
        return createColumn(this, { name })
    }

}

const createColumn = (column: ColumnInterface, values?: Partial<ColumnInterface>) => new Column(values ? { ...column, ...values } : column)